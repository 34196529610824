import React from "react";
import { Table, Button, Row, Col,Select} from "antd";
import { withRouter } from "react-router-dom";
import { API, graphqlOperation, Auth} from 'aws-amplify';
import Linkify from 'react-linkify';
import json2csv from 'json2csv';
import awsconfig from '../aws-exports';
Auth.configure(awsconfig);
API.configure(awsconfig);

const listApproved = /* GraphQL */ `
  query listSubmitted{
    storiesByStatus(Status: "approved") {
      items {
        DateofSubmission
        PartnerName
        ARR
        CustomerName
        CustomerRevenue
        GNSType
        Geo
        Industry
        PRLink
        PartnerTier
        ReferenceAble
        Status
        Story
        SubmitterName
        YrQtr
        ReadableARR
        ReadableCustomerRevenue
        Title
        Feedback
      }
    }
  }
`;

class StoriesCombiner extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      approvedStories:[],
      selectedRows: [],
      isCopied : false,
      dataSource1:[]
    };
  }

  componentDidMount() {
    this.fetchItems();
  }
  
  fetchItems = async () => {
    try{
      const response = await API.graphql(graphqlOperation(listApproved));
      //console.log('Res stories---->',response.data.storiesByStatus.items);
      this.setState({approvedStories: response.data.storiesByStatus.items});
      //console.log('approvedStories---->',this.state.approvedStories);
      this.setState({dataSource1: response.data.storiesByStatus.items});
      console.log('dataSource1---->',this.state.dataSource1);
    } catch (err) {console.log('error', err)}
  };

  // a function to get all different values of Year and Quarters
  getYrQtrs = (data) => {
    data = data || []
    let yrQtrs = []
    data.forEach(d => {
      if (yrQtrs.indexOf(d.YrQtr) < 0) {
        yrQtrs.push(d.YrQtr)
      }
    })
    yrQtrs.sort()
    yrQtrs.reverse()
    return yrQtrs
  }
  
  handleFilterYrQtr = (YrQtr) => {
    const newData = this.state.approvedStories.filter(items => items.YrQtr === YrQtr)
    this.setState({
      dataSource1: newData
    })
  }
  
  handleCleanFilter = () => {
    this.setState({
      dataSource1: this.state.approvedStories
    })
  }

  handleDownload = (inputData, columnsParams) => {
    columnsParams = columnsParams || []
    const filename = 'export.csv'
    const BOM = '\uFEFF';
    const columns = columnsParams.map(d => d.dataIndex)
    console.log('columns---->',columns);
    const csv =  new json2csv.Parser({
      fields: columns
    }).parse(inputData);

    if (window.navigator.msSaveOrOpenBlob) {
      let blob = new global.Blob([BOM + csv], { type: 'text/csv;charset=utf-8;' });
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      let uri = encodeURI(`data:text/csv;charset=utf-8,${BOM}${csv}`);
      let downloadLink = document.createElement('a');
      downloadLink.href = uri;
      downloadLink.download = filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }
  
  handleCopyDivToClipboard = () => {
    var range = document.createRange();
    range.selectNode(document.getElementById("CombinedStories"));
    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    document.execCommand("copy");
    window.getSelection().removeAllRanges();// to deselect
  }

  render() {
    
    
    const {approvedStories,selectedRows,dataSource1} = this.state;
    
    const columns = [
    {
      dataIndex: 'GNSType',
      title: 'Type',
      width: 125,
      sorter: (a, b) => { return a.GNSType?.localeCompare(b.GNSType)}
    }, {
      dataIndex: 'CustomerName',
      title: 'Customer Name',
      width: 125,
      sorter: (a, b) => { return a.CustomerName?.localeCompare(b.CustomerName)}
    }, {
      dataIndex: 'Geo',
      title: 'Geo',
      width: 75,
      sorter: (a, b) => { return a.Geo?.localeCompare(b.Geo)}
    }, {
      dataIndex: 'Industry',
      title: 'Industry',
      width: 130,
      sorter: (a, b) => { return a.Industry?.localeCompare(b.Industry)}
    }, {
      dataIndex: 'ReadableCustomerRevenue',
      title: 'Customer Revenue',
      width: 100,
      sorter:(a,b)=> a.CustomerRevenue - b.CustomerRevenue
    }, {
      dataIndex: 'PartnerName',
      title: 'Partner Name',
      width: 100,
      sorter: (a, b) => { return a.PartnerName?.localeCompare(b.PartnerName)}
    }, {
      dataIndex: 'PartnerTier',
      title: 'Partner Tier',
      width: 120,
      sorter: (a, b) => { return a.PartnerTier?.localeCompare(b.PartnerTier)}
    }, {
      dataIndex: 'ReadableARR',
      title: 'ARR',
      width: 90,
      sorter:(a,b)=> a.ARR - b.ARR
    }, {
      dataIndex: 'Title',
      title: 'Title',
      width: 250,
    },{
      dataIndex: 'Story',
      title: 'Story'
    },  {
      dataIndex: 'ReferenceAble',
      title: 'Refer-Able',
      width: 120,
      sorter: (a, b) => { return a.ReferenceAble?.localeCompare(b.ReferenceAble)}
    }, {
      dataIndex: 'SubmitterName',
      title: 'Submitter',
      width: 120,
      sorter: (a, b) => { return a.SubmitterName?.localeCompare(b.SubmitterName)}
    }, {
      dataIndex: 'DateofSubmission',
      title: 'Date of Submission',
      width: 120,
      sorter: (a, b) => { return a.DateofSubmission?.localeCompare(b.DateofSubmission)}
      
    }, {
      dataIndex: 'YrQtr',
      title: 'Yr&Qtr',
      fixed: 'right',
      width: 105,
      filterDropdown: () => {
          const yrQtrs = this.getYrQtrs(approvedStories)
          return (
            <div style={{padding: 20}}>
              <span>Yr&Qtr: </span>
              <Select
                style={{width: 120, margin: '0 20px'}}
                onChange={this.handleFilterYrQtr}
              >
                {yrQtrs.map(d => {
                  return (
                    <Select.Option value={d}>{d}</Select.Option>
                  )
                })}
              </Select>
              <Button onClick={this.handleCleanFilter} style={{marginLeft: 10}} size="small">Reset</Button>
            </div>
          )
        },
      sorter: (a, b) => { return a.YrQtr?.localeCompare(b.YrQtr)},
      defaultSortOrder: 'descend'
    }];

    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRowKeys,
          selectedRows,
          isCopied:false
        });
        console.log('Select rows---->',selectedRows);
      }
      
    };


    return (
      <div>
        <h3>Stories Combiner</h3>
        <span>Click on + to expand stories</span>
        <br/>
        <span>Click on - to hide stories</span>
        <br/>
        <span>Select Stories to get Story Format Result</span>
        <br/>
        {/* check if there are data before rendering, so that defaultExpandAllRows will work*/}
        
        <Table
          rowKey={(d) => d.DateofSubmission}
          columns={columns.filter(col => col.dataIndex !== 'Story')}
          dataSource={dataSource1}
          rowSelection={rowSelection}
          scroll={{y:1000, x:500}}
          
          //expandIconAsCell={false}
          expandable={{
            expandedRowRender: record => <p style={{ margin: 0 }}>{record.Story}</p>
          }}
        />
        <h4>Results:</h4>
        <Button 
         size="small"
         type="primary"
         style={{marginRight:10}}
         onClick={() => {this.handleCopyDivToClipboard();
            this.setState({
              isCopied:true
            });
          }}
        >Copy</Button>
        <Button
          size="small"
          style={{marginRight:10}}
          onClick={() => {
            this.setState({
              selectedRows: [],
              selectedRowKeys: [],
              isCopied:false
            });
          }}
        >Reset
        </Button>
        <Button
        type="primary"
          size="small"
          onClick={() => {
            this.handleDownload(selectedRows, columns)
          }}
        >Download csv
        </Button>
        <br/>
        {(this.state.selectedRows.length !== 0 && this.state.isCopied) ? <span> Copied to clipboard.</span> : null}
        <div id="CombinedStories">
          <Row 
            gutter={24}
            style={{ marginTop: 10, border: "1px solid #f0f0f0", maxWidth: 1200 }}
          >
            <Col span={24} style={{margin: '5px 0'}}>
              {selectedRows.map((d) => {
                return (
                  <div>
                    <span style={{ fontWeight:'bold' }}> {d.CustomerName} ({d.ReadableCustomerRevenue && <span>${d.ReadableCustomerRevenue} </span>}{d.Industry}, {d.Geo}) 
                    - {d.PartnerName} (GSI, {d.PartnerTier}) -  {d.Title}, { d.ReadableARR && <span>${d.ReadableARR} ARR</span>}
                    </span>
                    <br />
                    <span > {d.Story}</span>
                    <br />
                    { d.ReferenceAble && d.PRLink && d.ReferenceAble === "Yes" &&
                       <Linkify>Public Reference Link: {d.PRLink}</Linkify> 
                    }
                    <hr/>
                  </div>
                );
              })}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(StoriesCombiner);
