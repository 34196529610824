import React, { useState, useEffect } from "react";
import { Table } from 'antd';
import { withRouter } from "react-router-dom"


import { API, graphqlOperation, Auth} from 'aws-amplify';
//import {listStorys} from '../graphql/queries'
import awsconfig from '../aws-exports';
Auth.configure(awsconfig);
API.configure(awsconfig);

// GraphQL query for get stories
const listMyStorys = /* GraphQL */ `
  query ListStorys(
    $filter: ModelStoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        DateofSubmission
        PartnerName
        ARR
        CustomerName
        CustomerRevenue
        GNSType
        Geo
        Industry
        PRLink
        PartnerTier
        ReferenceAble
        Status
        Story
        SubmitterName
        YrQtr
        ReadableARR
        ReadableCustomerRevenue
        Title
        Feedback
      }
      nextToken
    }
  }
`;


const MySubmitStoryPage = props => {

  //const [user, setUser] = useState({});
  const [userEmailAlias, setUserAlias] = useState({});
  const [stories, setItems] = useState([]);
  
  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const data = await Auth.currentUserPoolUser();
      const userInfo = { username: data.username, ...data.attributes, };
      console.log('userInfo--->',userInfo);
      const userEmailAlias = userInfo.username.split('_')[1]
      console.log('userAlias--->',userEmailAlias);
      //setUser(userInfo);
      setUserAlias(userEmailAlias);
      const response = await API.graphql(graphqlOperation(listMyStorys, {
          limit: 100,
          filter: {
              SubmitterName: {
                  eq: userEmailAlias
              }
          }
      }));
      console.log('data in item--->',response.data.listStorys.items);
      setItems(response.data.listStorys.items);
    } catch (err) { console.log('error: ', err) }
  };
  
  // set columns of the table
  const columns = [{
      
    title: '#',
    render:(text,record,index)=>`${index+1}`,
    width: 51
  }, {
    dataIndex: 'DateofSubmission',
    title: 'Date of Submission',
    width: 120,
    sorter: (a, b) => { return a.DateofSubmission.localeCompare(b.DateofSubmission)},
    defaultSortOrder: 'descend'
  }, {
    
    dataIndex: 'GNSType',
    title: 'Type',
    width: 125,
    sorter: (a, b) => { return a.GNSType.localeCompare(b.GNSType)}
  }, {
    dataIndex: 'CustomerName',
    title: 'Customer Name',
    width: 125,
    sorter: (a, b) => { return a.CustomerName.localeCompare(b.CustomerName)}
  }, {
    dataIndex: 'Geo',
    title: 'Geo',
    width: 75,
    sorter: (a, b) => { return a.Geo.localeCompare(b.Geo)}
  }, {
    dataIndex: 'Industry',
    title: 'Industry',
    width: 130,
    sorter: (a, b) => { return a.Industry.localeCompare(b.Industry)}
  }, {
    dataIndex: 'ReadableCustomerRevenue',
    title: 'Customer Revenue',
    width: 120,
    sorter:(a,b)=> a.CustomerRevenue - b.CustomerRevenue
  }, {
    dataIndex: 'PartnerName',
    title: 'Partner Name',
    width: 120,
    sorter: (a, b) => { return a.PartnerName.localeCompare(b.PartnerName)}
  }, {
    dataIndex: 'PartnerTier',
    title: 'Partner Tier',
    width: 120,
    sorter: (a, b) => { return a.PartnerTier.localeCompare(b.PartnerTier)}
  }, {
    dataIndex: 'ReadableARR',
    title: 'ARR',
    width: 100,
    sorter:(a,b)=> a.ARR - b.ARR
  }, {
    dataIndex: 'Title',
    title: 'Title',
    width: 250
  }, /*{
    dataIndex: 'Story',
    title: 'Story',
    width: 550
  }, */
  {
    dataIndex: 'ReferenceAble',
    title: 'Refer-Able',
    width: 100,
    sorter: (a, b) => { return a.ReferenceAble.localeCompare(b.ReferenceAble)},
    
  }, {
    dataIndex: 'YrQtr',
    title: 'Yr&Qtr',
    width: 85,
    sorter: (a, b) => { return a.YrQtr.localeCompare(b.YrQtr)},
  },  /*{
    dataIndex: 'SubmitterName',
    title: 'Submitter',
    width: 120,
    //sorter: (a, b) => { return a.SubmitterName.localeCompare(b.SubmitterName)}
  }, */{
    dataIndex: 'Status',
    title: 'Status',
    width: 100,
    sorter: (a, b) => { return a.Status.localeCompare(b.Status)},
    fixed: 'right'
  }, {
    dataIndex: 'Feedback',
    title: 'Feedback',
    width: 150,
    sorter: (a, b) => { return a.Status.localeCompare(b.Status)},
    fixed: 'right'
  }];
  
  function onChange(pagination, filters, sorter, extra) {
    console.log('params', pagination, filters, sorter, extra);
  }
  
  return (
 
    <div>
      <h3>Stories submitted by {userEmailAlias.toString()}</h3>
        <span>Click on + to expand stories</span>
        <br/>
        <span>Click on - to hide stories</span>
      <Table
        dataSource={ stories }
        columns={ columns }
        rowKey={(d) => d.DateofSubmission}
        scroll={{y:1000, x:500}}
        onChange={onChange}
        defaultExpandAllRows={true}
        expandable={{
          expandedRowRender: record => <p style={{ margin: 0 }}>{record.Story}</p>
          
        }}
      /> 
      
    </div>
  );
};


export default withRouter(MySubmitStoryPage)
