import React from 'react';
import {Button} from 'react-bootstrap';


const SuccessPage = () => {

  const handleGoList = () => {
    window.location.href = '/welcomePage'
  }

  return (
    <div>
      <h3>Thank you for submitting the Good News Story. </h3>
      <h3>You story is pending.</h3>
      <div>
        <Button onClick={handleGoList}>Go back to Welcome page</Button>
      </div>
    </div>
  )
}

export default SuccessPage