import React from "react";
import { withRouter } from "react-router-dom"
import smile_logo from '../images/amazon_smile.png'
import Vivek from '../images/Vivek.png'
import Saurabh from '../images/Saursh.png'
import Sameer from '../images/Sameer.png'
import Stephen from '../images/Stephen.png'
import Roy from '../images/Roy.png'
import Eric from '../images/Eric.png'
const TeamStats = props => {
  
  return (
  
    <div className="teamImages">
      
      <div className="teamImages">
      <h1>Meet the minds behind GNS Project</h1>
      </div>
     
      <div className="teamImages">
      <a href="https://phonetool.amazon.com/users/goesamee" target="_blank" rel="noopener noreferrer"><img src={Sameer} alt="" height="400px"/></a>
      <a href="https://phonetool.amazon.com/users/saursh" target="_blank" rel="noopener noreferrer"><img src={Saurabh} alt="" height="400px"/></a>
      <a href="https://phonetool.amazon.com/users/rajuvi" target="_blank" rel="noopener noreferrer"><img src={Vivek} alt="" height="400px"/></a>
      
      </div>
      
      <div className="teamImages">
      <a href="https://tiny.amazon.com/aaueuk4z/gns-feedback" >
     {/* <AnimatedButton buttonStyle={{padding: '0.5rem'}} color="white" buttonRadius="50%"><span role="img" aria-label="thumb">👍</span></AnimatedButton>
      <AnimatedButton color="#FF8F28" textColor="white" animationDuration={1} animationType="pulse">Send Feedback</AnimatedButton>
  <AnimatedButton buttonStyle={{padding: '0.5rem'}} color="white" buttonRadius="50%"><span>❤</span>️</AnimatedButton> */}
      </a>
      </div>
    
      <div>
      <a href="https://w.amazon.com/bin/view/AWS/Teams/Partner_Program/GSII_GNS_Repository" target="_blank" rel="noopener noreferrer"><img src={smile_logo} alt=""/></a>
      </div>
    
    </div>
  );
};


export default withRouter(TeamStats)
