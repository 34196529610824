import React, { useState, useEffect } from "react";
import { Button, Table } from 'antd';
//import { SearchOutlined } from '@ant-design/icons';

import { withRouter } from "react-router-dom";
import { API, graphqlOperation, Auth} from 'aws-amplify';
//import {listStorys} from '../graphql/queries'
import awsconfig from '../aws-exports';
Auth.configure(awsconfig);
API.configure(awsconfig);

// GraphQL query for get all "submitted" stories from database
const listSubmitted = /* GraphQL */ `
  query listSubmitted{
    storiesByStatus(Status: "submitted") {
      items {
        DateofSubmission
        PartnerName
        ARR
        CustomerName
        CustomerRevenue
        GNSType
        Geo
        Industry
        PRLink
        PartnerTier
        ReferenceAble
        Status
        Story
        SubmitterName
        YrQtr
        ReadableARR
        ReadableCustomerRevenue
        Title
        Feedback
      }
    }
  }
`;

/*----------Class way----------------
class Dashboard extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      stories:''
    };
  }

  componentDidMount() {
    this.fetchItems();
  }
  
  fetchItems = async () => {
    try{
      const response = await API.graphql(graphqlOperation(listSubmitted));
      this.setState({stories: response.data.listStorys.items});
      console.log('pending stories----->',this.state.stories);
    } catch (err) {console.log('error', err)}
  };

  render() {
*/
  
/*-----------Hooks way--------------*/  
const Dashboard = props => {
  
  // to get and set stories as data in the table using GraphQL API
  const [stories, setStories] = useState([]);
  
  useEffect(() => {
      fetchItems();
    }, []);
    
  const fetchItems = async () => {
    try{
      const response = await API.graphql(graphqlOperation(listSubmitted));
      // console.log("response---->",response.data.listStorys.items);
      let items = response.data.storiesByStatus.items.filter(item => item.Status === "submitted");
      //setStories(response.data.listStorys.items);      
      setStories(items);
    } catch (err) {console.log('error', err)}
  };
  
    
    // set columns of the table
    const columns = [{
        
      title:"#",
      render:(text,record,index)=>`${index+1}`,
      width: 51,
      key:'Number'
    }, {
      dataIndex: 'DateofSubmission',
      title: 'Date of Submission',
      width: 120,
      sorter: (a, b) => { return a.DateofSubmission.localeCompare(b.DateofSubmission)},
      defaultSortOrder: 'descend',
      key:'DateofSubmission'
    }, {
      dataIndex: 'GNSType',
      title: 'Type',
      width: 125,
      sorter: (a, b) => { return a.GNSType.localeCompare(b.GNSType)},
      key:'GNSType'
    }, {
      dataIndex: 'CustomerName',
      title: 'Customer Name',
      width: 125,
      sorter: (a, b) => { return a.CustomerName.localeCompare(b.CustomerName)},
      key:'CUstomerName'
    }, {
      dataIndex: 'Geo',
      title: 'Geo',
      width: 75,
      sorter: (a, b) => { return a.Geo.localeCompare(b.Geo)},
      key:'Geo'
    }, {
      dataIndex: 'Industry',
      title: 'Industry',
      width: 130,
      sorter: (a, b) => { return a.Industry.localeCompare(b.Industry)},
      key: 'Industry'
    }, {
      dataIndex: 'ReadableCustomerRevenue',
      title: 'Customer Revenue',
      width: 120,
      sorter:(a,b)=> a.CustomerRevenue - b.CustomerRevenue,
      key:'ReadableCustomerRevenue'
    }, {
      dataIndex: 'PartnerName',
      title: 'Partner Name',
      width: 120,
      sorter: (a, b) => { return a.PartnerName.localeCompare(b.PartnerName)},
      key:'PartnerName'
    }, {
      dataIndex: 'PartnerTier',
      title: 'Partner Tier',
      width: 120,
      sorter: (a, b) => { return a.PartnerTier.localeCompare(b.PartnerTier)},
      key:'PartnerTier'
    }, {
      dataIndex: 'ReadableARR',
      title: 'ARR',
      width: 100,
      sorter:(a,b)=> a.ARR - b.ARR,
      key:'ReadableARR'
    }, {
      dataIndex: 'Title',
      title: 'Title',
      width: 250,
      key:'Title'
    }, 
    /*{
       dataIndex: 'Story',
       title: 'Story',
       width: 580
     },*/ 
    {
      dataIndex: 'YrQtr',
      title: 'Yr&Qtr',
      width: 85,
      sorter: (a, b) => { return a.YrQtr.localeCompare(b.YrQtr)},
      key:'YrQtr'
    }, /*{
      dataIndex: 'Status',
      title: 'Status',
      width: 100,
      sorter: (a, b) => { return a.Status.localeCompare(b.Status)},
      key:'Status'
    }, */
    {
      dataIndex: 'ReferenceAble',
      title: 'Refer-Able',
      width: 100,
      sorter: (a, b) => { return a.ReferenceAble.localeCompare(b.ReferenceAble)},
      key:'ReferenceAble'
    },{
      dataIndex: 'SubmitterName',
      title: 'Submitter',
      width: 120,
      sorter: (a, b) => { return a.SubmitterName.localeCompare(b.SubmitterName)},
      key:'SubmitterName'
    },  {
      title:'Admin Operation',
      width: 120,
      key:'action',
      fixed: "right",
      render(val,curRowValues) {
        return (
          <div>
            <Button 
             size="small" 
             type="primary" 
             style={{width: 85}}
             onClick={() => {
                console.log('props', props);
                props.history.push({
                  pathname: '/approvePage',
                  state: {
                    item: curRowValues
                  },
                });
              }}
            >Approve
            </Button>
            <Button 
             size="small" 
             type="danger"
             style={{ width: 85 }}
             onClick={() => {
                console.log('props', props);
                props.history.push({
                  pathname: '/rejectPage',
                  state: {
                    item: curRowValues
                  },
                });
              }}
            >Reject
            </Button>
  
          </div>
          );
      }
      
    }];
    
    function onChange(pagination, filters, sorter, extra) {
      console.log('params', pagination, filters, sorter, extra);
    }
    
    return (
   
      <div>
        <h3>Pending Stories for Admin</h3>
        <span>Click on + to expand stories</span>
        <br/>
        <span>Click on - to hide stories</span>
        <Table 
          dataSource={ stories }
          columns={ columns }
          rowKey={(d) => d.DateofSubmission}
          expandable={{
            expandedRowRender: record => <p style={{ margin: 0 }}>{record.Story}</p>
          }}
          scroll={{y:1000, x:500}}
          onChange={onChange}
        />
        
      </div>
    );
};
  


export default withRouter(Dashboard);