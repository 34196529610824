export const request = (url, config) => {
  return fetch(url, config).then((res) => {
    if (res.ok) {
      return res.json()
    } else {
      throw Error('')
    }
  }).then((resJson) => {
    return resJson
  }).catch((error) => {
    //alert('err')
  })
}

export const get = (url) => {
  return request(url, { method: 'GET' })
}

export const post = (url, data) => {
  return request(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    mode: 'cors',
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(data)
  })
}


