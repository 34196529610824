import React from "react";
import { Form, Button, Col, InputGroup} from 'react-bootstrap';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import {post} from '../utils/request'
import { withRouter } from "react-router-dom"
import awsconfig from '../aws-exports';

import { Auth } from 'aws-amplify';
Auth.configure(awsconfig);


// to setup validation schema for required(not empty) and number only fields
const schema = yup.object({
  type: yup.string().required(),
});



// to validate min and max words in body textarea
const wordsCountValidater = (value) => {
  let error;
  if (value) {
    const values = value.split(' ')
    if (values && values.length < 51) {
      error = 'You cannot input less than 100 words.'
    }
    if (values && values.length >= 150) {
      error = 'You cannot input more than 150 words'
    }
  }
  return error;
}


const RejectPage = props => {
  
  
  // get item from props if it's from clicking on admin page edit button
  const {item={}} = props.location.state || {}
  console.log('form page props-->>', props.location.state)

  return (
    <div>
      <Formik
        initialValues={{
          arr:item.ARR,
          readableARR:item.ReadableARR,
          customerName: item.CustomerName,
          customerRev:item.CustomerRevenue,
          readableCustomerRevenue:item.ReadableCustomerRevenue,
          type:item.GNSType,
          geo:item.Geo,
          industry:item.Industry,
          prlink:item.PRLink,
          partnerName:item.PartnerName,
          partnerTier:item.PartnerTier,
          refAble:item.ReferenceAble,
          title:item.Title,
          story:item.Story,
          submitter:item.SubmitterName,
          dateOfSubmission:item.DateofSubmission,
          yrQtr:item.YrQtr,
          status:"rejected",
          feedback:''
        }}
        enableReinitialize
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          console.log('values-->>', values)
          post('https://qmilj330s1.execute-api.us-west-2.amazonaws.com/dev/reject', values).then(res => {
          
            //display the message
            alert(res.body)
            // go to dashboard if success reject
            props.history.push({pathname: '/dashboard'})
            setSubmitting(false);
          })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
          
            
            <Form noValidate onSubmit={handleSubmit} style={{maxWidth:1000, margin:"auto"}}>
              <h3>Reject story</h3>
              <Form.Row>
              {/*-----------Step1: choose Wins & Opportunities or Customer highlights------------------------ */}
                <Form.Group as={Col} md="10">
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.type}
                    readOnly
                  />
                </Form.Group>
              </Form.Row>
              
              
              {/*-----------------------Step2: for headline------------------------ */}
              {values && values.type &&
              <Form.Row>
                <Form.Group md="10" style={{width:"210px", marginRight: "20px", marginLeft:"5px" }} >
                  <Form.Label>Customer Name</Form.Label>
                  <Form.Control
                    name="customerName"
                    value={values.customerName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.customerName}
                    readOnly
                  />
                </Form.Group>

                <Form.Group md="10" style={{marginRight: "20px", width:"110px"}}>
                  <Form.Label>Geo</Form.Label>
                  <Form.Control
                    name="geo"
                    value={values.geo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.geo}
                    readOnly
                  />
                </Form.Group>
                
                <Form.Group md="10" style={{marginRight: "20px", width:"300px"}}>
                  <Form.Label>Customer Industry</Form.Label>
                  <Form.Control
                    name="industry"
                    value={values.industry}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.industry}
                    readOnly
                  />
                </Form.Group>

                <Form.Group md="10" >
                  <Form.Label>Customer Revenue</Form.Label>
                  <InputGroup>
                  <InputGroup.Prepend>
                      <InputGroup.Text>$</InputGroup.Text>
                    </InputGroup.Prepend>
                  <Form.Control
                    name="readableCustomerRevenue"
                    value={values.readableCustomerRevenue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.readableCustomerRevenue}
                    style={{width:118}}
                    readOnly
                  />
                  </InputGroup>
                </Form.Group>
                
                <Form.Row>
                <Form.Group md="10" style={{width:"210px",marginRight: "20px",marginLeft:"10px"}}>
                  <Form.Label>Partner Name</Form.Label>
                  <Form.Control
                    name="partnerName"
                    value={values.partnerName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.partnerName}
                    readOnly
                  />
                </Form.Group>
                
                <Form.Group md="10" style={{ width:"110px",marginRight: "20px"}}>
                  <Form.Label>Partner Tier</Form.Label>
                  <Form.Control
                    name="partnerTier"
                    value={values.partnerTier}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.partnerTier}
                    readOnly
                  />
                </Form.Group>
                </Form.Row>
                
                {/* ARR for only Wins & Opportunities*/}
                {values && (values.type === 'Wins & Opportunities') &&
                <Form.Group md="10" style={{marginLeft:'4px'}}>
                  <Form.Label>ARR</Form.Label>
                  <InputGroup>
                  <InputGroup.Prepend>
                      <InputGroup.Text>$</InputGroup.Text>
                    </InputGroup.Prepend>
                  <Form.Control
                    name="readableARR"
                    value={values.readableARR}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.readableARR}
                    style={{maxWidth:100}}
                    readOnly
                  />
                  </InputGroup>
                </Form.Group>
                }
                <Form.Group as={Col} md="10">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.title}
                    readOnly
                  />
                </Form.Group>
                
                {/*--------------------------Step3: for Body------------------------ */}
                
                <Form.Group as={Col} md="10">
                  <Form.Label>Story:</Form.Label>
                  <Field name="story" style={{display: 'none'}} validate={wordsCountValidater} />
                  <Form.Control
                    as="textarea"
                    rows="8"
                    name="story"
                    placeholder="min 100 and max 150 words"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.story}
                    readOnly
                  />
                  
                  {/* words count */}
                  <div className="WordsCount">{`${values.story ? values.story.split(' ').length : 0}/150`}</div>

                </Form.Group>
                
                <Form.Row>
                {/*--------------------------Step3.1: only for Wins Body------------------------ */}


                <Form.Group md="10" as={Col}>
                  <Form.Label>Is the story reference-able to public?</Form.Label>
                  <Form.Control
                    name="refAble"
                    value={values.refAble}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.refAble}
                    style={{width:270, marginRight:730}}
                    readOnly
                  />
                </Form.Group>

                
                {values && values.refAble === 'Yes' &&
                  <Form.Group as={Col} md="10">
                    <Form.Label>Link of Public Reference(if any)</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="prlink"
                      rows="2"
                      value={values.prlink}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.prlink}
                      readOnly
                    />
                  </Form.Group>
                }
                </Form.Row>
                
                <Form.Group md="10" style={{marginRight: "20px", width:"270px"}}>
                  <Form.Label>Submitter(Email Alias)</Form.Label>
                  <Form.Control
                    name="submitter"
                    value={values.submitter}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.submitter}
                    readOnly
                  />
                </Form.Group>
                <Form.Group  md="10" style={{marginRight: "20px", width:"270px"}}>
                  <Form.Label>Date of Submission</Form.Label>
                  <Form.Control
                    name="dateOfSubmission"
                    value={values.dateOfSubmission}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.dateOfSubmission}
                    readOnly
                  />
                </Form.Group>
                <Form.Group md="10" style={{marginRight: "20px", width:"255px"}}>
                  <Form.Label>Year & Quarter</Form.Label>
                  <Form.Control
                    name="yrQtr"
                    value={values.yrQtr}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.yrQtr}
                    readOnly
                  />
                </Form.Group>
                <Form.Group as={Col} md="10">
                  <Form.Label>Feedback</Form.Label>
                  <Form.Control
                    name="feedback"
                    value={values.feedback}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.feedback}
                  />
                </Form.Group>
              </Form.Row>
              }
              {/*--------------------------Step4: for Submit------------------------ */}
              {/*<Button variant="primary" type="submit" disabled={isSubmitting} style={{marginRight: 10}}>
                Submit
              </Button>*/}
              {/*----------------------Confirm buttons area-------------------------------*/}
              <h4>Are you sure to REJECT this Good News Story?</h4>
              <Button 
                size="sm" 
                variant="danger" 
                type="submit"
                disabled={isSubmitting}
                style={{marginRight: 10}}
                >Reject
              </Button>
              
              <Button 
                size="sm" 
                variant="primary"
                style={{marginRight: 10}}
                onClick={() => {props.history.push({pathname: '/dashboard'})}}
                >Cancel
              </Button>
      
    
            </Form>
          )
        }}
      </Formik>
    </div>
    
  )
}
export default withRouter(RejectPage)