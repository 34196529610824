import React, { useState, useEffect } from "react";
import Amplify, { Auth} from 'aws-amplify';
import {Jumbotron, Button} from 'react-bootstrap'
import aws_exports from '../aws-exports';
import awsconfig from '../config.json';

const WelcomePage = () => {

  const [userGroup, setUserGroup] = useState([]);
  useEffect(() => {
      fetchUserGroup();
    }, []);
    
  const fetchUserGroup = async () => {
    try{
      
      const response = await Auth.currentAuthenticatedUser();
      const userGroups = response.signInUserSession.idToken.payload['cognito:groups']
      console.log(userGroups)
      if (userGroups.indexOf("Admins") !== -1)
          {
            setUserGroup("Admins")
          }
      else {setUserGroup("Users")}
      console.log('user role--->',userGroup);
} catch (err) {console.log('error', err)}
  };


  return (
    <div>
 
      <Jumbotron style={{maxWidth:1000, margin:"auto", marginTop:"3rem"}}>
        <h1>Welcome to Good News Stories</h1>
        <hr/>
        <h3>Overview</h3>
        <p>We have a great opportunity to highlight team's wins and success this week in Good News Stories for Doug and his leadership team.</p> 
        <p>These stories will be sent to Chris and Rohan for their weekly discussion with Doug.
        It is a wonderful chance to show the LT how we are contributing to AWS' and our partners' continued success. 
        Good news stories can include anything you feel worth sharing with our LT.</p> 
        <hr/>
        <h3>Submission Process</h3>
        <u>Deadline:</u>
        <li>All 'Good News Stories' must be submitted by Monday noon PST for the following Tuesday's LT review.</li>
        <u>Features of this web application:</u>
        <li>Click on "New Story" to submit the Story form.</li>
        <li>Click on "My Stories" to see your submitted stories.</li>
        {userGroup === 'Admins' &&
        <li>Click on "Dashboard" to manage the all the 'Pending' stories (for administrators only).</li>}
        {userGroup === 'Admins' &&
        <li>Click on "Combiner" to copy or download the 'Approved' stories (for administrators only).</li>
        }
        {userGroup === 'Admins' &&
        <li>Click on "Stats" to see statistics (for administrators only).</li>
        }
        <li>Click on "Team" to Meet the minds behind GNS Project.</li>
        <hr/>
        
          <Button variant="primary" href="https://w.amazon.com/bin/view/AWS/Teams/Partner_Program/GSII_GNS_Repository" target="_blank"
          >Learn more</Button>
          
        
      </Jumbotron>
      {/*--------------- The Carousel is not used----------------------
      <Carousel style={{maxWidth:1000}}>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require('./first-slide.svg')}
            alt="First slide"
          />
          <Carousel.Caption>
            <h3 style={{color:'white'}}>$1.1 Million ARR, 4 Years(Initial contract)</h3>
            <p style={{fontSize:18}}>Thyssenkrupp AG (€42.7B Manufacturing, Essen, Germany)/ DXC Technologies GSI, Premier)</p>
          </Carousel.Caption>
        </Carousel.Item>
        
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require('./second-slide.svg')}
            alt="Second slide"
          />
          <Carousel.Caption>
            <h3 style={{color:'white'}}>ARR $3.3M (Azure Compete)</h3>
            <p style={{fontSize:18}}>Manpower Group ($22B, US Central) – Wipro (GSI, Premier)</p>
          </Carousel.Caption>
        </Carousel.Item>
        
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={require('./third-slide.svg')}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h3 style={{color:'white'}}>Accenture deploys classified SAP Enterprise Resource Planning during COVID-19</h3>
            <p style={{fontSize:18}}>First ERP workload in DoD Impact Level 6 in the AWS cloud </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      */}
    </div>
    
    
    
  )
}
  

export default WelcomePage
