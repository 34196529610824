import React, { useState, useEffect } from "react";
import { Menu } from 'antd';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect
} from "react-router-dom";

import FormPage from './form'
import Dashboard from './dashboard'
import StoriesCombiner from './storiesCombiner'
import MySubmitStoryPage from './mySubmitStories'
import ApprovePage from './approvePage'
import RejectPage from './rejectPage'
import WelcomePage from './welcomePage'
import SuccessPage from './successPage'
import StatsPage from './statsPage'
import TeamPage from './teamPage'
import {Amplify, Hub, Auth} from 'aws-amplify';
import aws_exports from './aws-exports';
import { withAuthenticator, SignOut  } from 'aws-amplify-react';
import Logo from './images/gnsLogo.png';
import awsconfig from './config.json';

import Authentication from './Auth/Authentication';

const log_level = awsconfig.log_level

Amplify.Logger.LOG_LEVEL = log_level;

Amplify.configure(aws_exports);

Amplify.configure({
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken(),
      };
    },
  },
});

console.log("Here")

console.log(window.location.host)

const oauth = {
  domain: awsconfig.auth_domain,
  scopes: ["openid"],
  redirectSignIn: window.location.protocol + '//' + window.location.host,
  redirectSignOut: window.location.protocol + '//' + window.location.host,
  responseType: 'token'
}

var hasAccess = "start"
var authState = {
      username: "",
      groups: [],
      posix : "",
      email : ""
    }
    


Auth.configure({oauth});

const App = () => {
 
    const [isAuthenticated, setIAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userGroup, setUserGroup] = useState([]);
  
     const refreshPage = () => {
      window.location.reload(true);
    }
    
    
  
    Hub.listen('auth', (data) => {
      console.log(data)
        switch (data.payload.event) {
            case 'signIn':
                setIAuthenticated(true);
                break;
            case 'signIn_failure':
              setIAuthenticated(false);
                break;
            default:
                break;
        }
      });
  
  
    useEffect(() => {
    
        if (window.location.hash.indexOf("#/access_token=") !== -1)
        {
          setIsLoading(true);
        }
        
        Auth.currentAuthenticatedUser().then(data => {
            console.log("data",data)
            const payload = {data}
            authState = {
            username: payload['data']['signInUserSession']['idToken']['payload']['identities'][0].userId,
            groups: payload['data']['signInUserSession']['idToken']['payload']['cognito:groups'],
            posix: payload['data']['signInUserSession']['idToken']['payload']['custom:gns-dev-claims'],
            email: payload['data']['signInUserSession']['idToken']['payload'].email
          }
          
          if (authState.groups.indexOf("Admins") !== -1)
            {
              setUserGroup("Admins")
            }
          else {setUserGroup("Users")}
            hasAccess = authState.posix.includes("gns")
            setIAuthenticated(true)
            console.log("hasAccess",hasAccess)
            console.log("isAuthenticated",isAuthenticated)

          }).catch(() => {
            hasAccess = false
            setIAuthenticated(false)
            console.log("hasAccess",hasAccess)
            console.log("isAuthenticated",isAuthenticated)

          });
    
        }, []);
    
    
      const handleSignIn = () => {
        Auth.federatedSignIn({customProvider:"gns-federate"});
      }
  
   // console.log(authState)
   //  if  (authState.posix.includes("users"))
    //    { userGroup = "Admins" }
   //     else 
   //       { userGroup = "Users" }
    //      console.log(userGroup)
    
  // const fetchUser = async () => {
  //   try{
  //     const response = await Auth.currentAuthenticatedUser();
  //     // to get user group(Admins or Users) from cognito
  //     const userGroup = response.signInUserSession.idToken.payload['cognito:groups']
  //     // console.log('userrole--->',userGroup[0]);
  //     setUserGroup(userGroup[0]);
  //     // to get user amazon alias
  //     const data = await Auth.currentUserPoolUser();
  //     const userInfo = { username: data.username, ...data.attributes, };
  //     // console.log('userInfo--->',userInfo);
  //     const userEmailAlias = userInfo.email.split('@')[0]
  //     // console.log('userAlias--->',userEmailAlias);
  //     setUserAlias(userEmailAlias);
  //   } catch (err) {console.log('error', err)}
  // };
  
 const handleGoList = () => {
    window.location.href = '/welcomePage'
  }
 //<img src={Logo} height='65px' alt="Good News Story" onClick={handleGoList}/>
  
  
  return (
    
   <div>
      { isAuthenticated  && (hasAccess === true) && 
    
    <Router className="container">      
      <div className="app-nav">
        <div className="logo">
          <img src={Logo} height='65px' alt="Good News Story" onClick={handleGoList} />
        </div>        
        <Menu
          theme="dark"
          mode="horizontal"
          style={{ lineHeight:'65px', height: '65px' }}        >

          <Menu.Item key="1"><Link to="/form">New Story</Link></Menu.Item>          
          <Menu.Item key="2"><Link to="/mySubmitStories">My Stories</Link></Menu.Item>          
          {userGroup === 'Admins' &&
          <Menu.Item key="3"><Link to="/dashboard">Dashboard</Link></Menu.Item>
          }
          {userGroup === 'Admins' &&
          <Menu.Item key="4"><Link to="/storiesCombiner">Combiner</Link></Menu.Item>
          }
          {userGroup === 'Admins' &&
          <Menu.Item key="5"><Link to="/statsPage">Stats</Link></Menu.Item>
          }
          
          <Menu.Item key="6"><Link to="/teamPage">Team</Link></Menu.Item>

          <div style={{float:'right',margin:'0 15px 5px 15px'}}> 
           <span style={{marginRight:'20px'}}>Hello, {authState.username.toString()}</span>
           <SignOut style={{width:'50px'}} className="logoutStyle"/>
          </div>
          
        </Menu>  
        
      </div>
      
      <div className="content">
        <div className="content-wrapper">
          <Route path="/welcomePage">
            <WelcomePage />
          </Route>
          <Route path="/form">
            <FormPage />
          </Route>
          <Route path="/mySubmitStories">
            <MySubmitStoryPage />
          </Route>
          <Route path="/successPage">
            <SuccessPage />
          </Route>
          {userGroup === 'Admins' &&
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          }
          {userGroup === 'Admins' &&
          <Route path="/storiesCombiner">
            <StoriesCombiner />
          </Route>
          }
          {userGroup === 'Admins' &&
          <Route path="/approvePage">
            <ApprovePage />
          </Route>
          }
          {userGroup === 'Admins' &&
          <Route path="/rejectPage">
            <RejectPage />
          </Route>
          }
          {userGroup === 'Admins' &&
          <Route path="/statsPage">
            <StatsPage />
          </Route>
          }
          <Route path="/teamPage">
            <TeamPage />
          </Route>
          <Redirect path="/" to="/welcomePage"/>
        </div>
      </div>
    </Router>
      }
      { isAuthenticated  && (hasAccess === "start") &&
      <div>
      {refreshPage()}
      </div>
      }
      { isAuthenticated  && (hasAccess === false) &&
      <h3>Error - Not a member of GNS posix groups</h3>
      }
      { ! isAuthenticated  && 
        <Authentication isLoading={isLoading} onSignIn={handleSignIn} />
      }
    </div>
  )
}

export default App;

//export default withAuthenticator(View);

// export default withAuthenticator(View, 
// {
//   //includeGreetings: true,
//   signUpConfig: {
//     hiddenDefaults: ['phone_number'],
//     signUpFields: [
//       { label: "Username", key: "username", required: true, type: "string", placeholder:"Please use Amazon Alias", displayOrder: 1 }
//     ]
//   }
// });