import React, { useState, useEffect } from "react";

import { withRouter } from "react-router-dom";
import { API, graphqlOperation, Auth} from 'aws-amplify';
import awsconfig from '../aws-exports';
import {
  PieChart, Pie, /*Sector*/ Cell, Legend, Tooltip, Label, text,BarChart, Bar, XAxis, YAxis, CartesianGrid, LineChart, Line,
} from 'recharts';



Auth.configure(awsconfig);
API.configure(awsconfig);

const getStoriesData = /* GraphQL */ `
  query getStoriesData{
    listStorys{
      items {
        DateofSubmission
        PartnerName
        ARR
        CustomerName
        CustomerRevenue
        GNSType
        Geo
        Industry
        PRLink
        PartnerTier
        ReferenceAble
        Status
        Story
        SubmitterName
        YrQtr
        ReadableARR
        ReadableCustomerRevenue
        Title
      }
    }
  }
`;

const GnsStats = () => {
  
  // to get and set stories as data in the table using GraphQL API
  const [stories, setStories] = useState([]);
  const [countTotal, setCountTotal] = useState([]);
  const [partnerTotal, setPartnerTotal] = useState([]);
  const [qtrTotal, setQtrTotal] = useState([]);
  // const [storyStatusData, setStoryStatusData] = useState([]);
  
  useEffect(() => {
    fetchItems();
    }, []);
    
  const fetchItems = async () => {
    try{
      const numStories = await API.graphql(graphqlOperation(getStoriesData));
      setStories(numStories.data.listStorys.items);
      const numStoriesArray = numStories.data.listStorys.items
      const storyStatus = numStoriesArray.map((n) => n.Status)
      calculateCount(storyStatus, setCountTotal);
      const storyPartnerName = numStoriesArray.map((n) => n.PartnerName)
      calculateCount(storyPartnerName, setPartnerTotal);
      const storyQtrName = numStoriesArray.map((n) => n.YrQtr)
      calculateCount(storyQtrName, setQtrTotal);
    } catch (err) {console.log('error', err)}
  };
  
  
  const calculateCount = (mydata, valToBeUpdated) => {
    let result = mydata.reduce((total, value) => {
    total[value] = (total[value] || 0) + 1; return total;}, {});
    valToBeUpdated(result);
  } 
    
    const generateKeyValePair = (objdata, arraytoupdated) => {
      Object.keys(objdata).forEach(function(key) {
	    var objNeeded = { name: 'Init', value: -1};
  	  objNeeded['name'] = key;
  	  objNeeded['value'] = objdata[key];
  	  arraytoupdated.push(objNeeded);
    });}
    
    const storyStatusData = [];
    generateKeyValePair(countTotal,storyStatusData);
    
    const storyPartnerData = [];
    generateKeyValePair(partnerTotal,storyPartnerData);
    
    const storyQtrData = [];
    generateKeyValePair(qtrTotal,storyQtrData);
    
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    
    function CreateBarChart(val) {
      return(
      <BarChart
            width={500}
            height={300}
            data={val.data}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="value"  >
            {val.data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)}
            </Bar>
            
      </BarChart> 
      );
    }
    
    
    function CreatePieChart(val) {
      return (
          <PieChart width={400} height={400}>
           <Pie 
           data={val.data} 
           cx="50%" cy="50%"
           labelLine={true}
           label
           innerRadius={val.ir}
           outerRadius={val.or} 
           fill="#8884d8"
           paddingAngle={2}
           dataKey="value"
           >
           <Label 
           value= {val.title}
           position="center"  className='label' fontSize='27px'
           />
            {val.data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} label/>)}
            
           </Pie>
           <Legend/>
           <Tooltip/>
          </PieChart>
    );}
    
    function CustomizedLabel(props) {
        const { x, y, stroke, value } = props;
        return (
          <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
            {value}
          </text>
        );

    }
    
    function CreateLineChart(val){
      console.log("unsort->",val.data);
      return(
        <LineChart
          width={500}
          height={300}
          data={val.data}
          margin={{
            top: 20, right: 30, left: 20, bottom: 10,
          }}
          >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" height={60} tick />
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="value" stroke="#8884d8" label={<CustomizedLabel />} />
        </LineChart>
        )
    }
    
    
    return (
        <div>
        <h2>Analytics</h2>  
          <table>
            <tbody>
            <tr>
                <td><CreatePieChart data={storyPartnerData} ir="70" or="100" title="Partners"/></td>
                <td><CreatePieChart data={storyStatusData} ir="70" or="100" title="Status"/></td>
                <td><CreatePieChart data={storyQtrData} ir="70" or="100" title="Quarterly"/></td>
            </tr>
            <tr>
                <td><CreateBarChart data={storyPartnerData} /></td>
                <td><CreateBarChart data={storyStatusData} /></td>
                <td><CreateBarChart data={storyQtrData} /></td>
            </tr>
            
            </tbody>
          </table>
        </div>
      );
};
  


export default withRouter(GnsStats);