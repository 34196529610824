import React, { useState, useEffect } from "react";
import { Form, Button, Col, InputGroup} from 'react-bootstrap';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import {post} from '../utils/request'
import { withRouter } from "react-router-dom"
import awsconfig from '../aws-exports';

import { Auth } from 'aws-amplify';
Auth.configure(awsconfig);

// to setup validation schema for required(not empty) and number only fields
const schema = yup.object({
  type: yup.string().required(),
  customerName: yup.string().required(),
  industry: yup.string().required(),
  partnerName: yup.string().required(),
  partnerTier: yup.string().required(),
  title: yup.string().required(),
  story:yup.string().required(),
  customerRev: yup.number(),
  arr:yup.number()
});



// to validate min and max words in body textarea
const wordsCountValidater = (value) => {
  let error;
  if (value) {
    const values = value.split(' ')
    if (values && values.length < 51) {
      error = 'You cannot input less than 50 words.'
    }
    if (values && values.length >= 150) {
      error = 'You cannot input more than 150 words'
    }
  }
  return error;
}


const FormPage = props => {
  
  // to get user email alias
  const [userEmailAlias, setUserAlias] = useState({});
  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const data = await Auth.currentUserPoolUser();
      console.log('userdata--->',data);
      const userInfo = { username: data.username, ...data.attributes, };
      console.log('userInfo--->',userInfo);
      const userEmailAlias = userInfo.username.split('_')[1]
      console.log('userAlias--->',userEmailAlias);
      //setUser(userInfo);
      setUserAlias(userEmailAlias);
    } catch (err) { console.log('error: ', err) }
  };
  
  const geoList = [
    {name: '--None--', id: 0},
    {name: 'AMER',id: 1},
    {name: 'EMEA',id: 2},
    {name: 'APAC',id: 3},
    {name: 'GLBL',id: 4},
    {name: 'CHNA',id: 5},
    {name: 'JAPN',id: 6}
  ]
  
  const partnerNameList = [
    {name: '--None--', id: 0},
    {name: 'Accenture',id: 1},
    {name: 'Atos',id: 2},
    {name: 'Capgemini',id: 3},
    {name: 'Cognizant',id: 4},
    {name: 'Deloitte',id: 5},
    {name: 'DXC',id: 6},
    {name: 'Kyndryl',id: 7},
    {name: 'Genpact',id: 8},
    {name: 'HCL',id: 9},
    {name: 'IBM',id: 10},
    {name: 'Infosys',id: 11},
    // {name: 'KPMG',id: 12},
    {name: 'PWC',id: 14},
    {name: 'TCS',id: 15},
    {name: 'Tech Mahindra',id: 16},
    {name: 'Wipro',id: 17}
  ]
  
  const partnerTierList = [
    {name: '--None--', id: 0},
    {name: 'Registered',id: 1},
    {name: 'Select',id: 2},
    {name: 'Advanced',id: 3},
    {name: 'Premier',id: 4}
  ]
  
  const unitList = [
    {name: '-Select an unit-', id: 0},
    {name: 'Billion',id: 1},
    {name: 'Million',id: 2},
    {name: 'Thousand',id: 3}
  ]
  /*------ Grammar Check which is not implemented-----------------
  const handleGrammarCheck = (story) => {
    const storyToCheck = story
    // post the storyToCheck to related API
    console.log('storyToCheck--->',storyToCheck);
    post('API for Grammar Check', storyToCheck).then(res => {
      //display the Grammar Check result
      alert(res.body)
    })
  }*/
  
  return (
    <div>
      <Formik
        initialValues={{
          submitter:userEmailAlias
        }}
        enableReinitialize
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          console.log('values-->>', values)
          post('https://qmilj330s1.execute-api.us-west-2.amazonaws.com/dev', values).then(res => {
            //display the message
            console.log('res-->>', res)
            alert(res.body)
            // go to successPage if success to submit
            if (res.statusCode===200){
              props.history.push({pathname: '/successPage'})
            }
          })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
          
            
            <Form noValidate onSubmit={handleSubmit} style={{maxWidth:1024, margin:"auto"}}>
              <h3>Submit a new Story</h3>
              
              
              {/*-----------Step1: choose Wins & Opportunities or Customer highlights------------------------ */}
              <Form.Row>
                {/*-----------Story Type Selection: Wins & Opportunities or Customer highlights------------------------ */}
                <Form.Group as={Col} md="10">
                  <Form.Label>Type</Form.Label>
                  <div>
                    <Form.Check
                      custom
                      inline
                      type="radio"
                      label="Wins & Opportunities"
                      value="Wins & Opportunities"
                      name="type"
                      id="typeWins&Opportunities"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.type}
                    />
                    <Form.Check
                      custom
                      inline
                      type="radio"
                      label="Customer Highlights"
                      value="Customer Highlights"
                      name="type"
                      id="typeCustomerHighlights"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.type}
                    />
                  </div>
                </Form.Group>
              </Form.Row>
              
              <Form.Row>
              {/*-----------Wins & Opportunities Example------------------------ */}
              <Form.Group as={Col} md="10">
                {values && values.type === 'Wins & Opportunities'  &&
                <div class="alert alert-secondary" role="alert">
                  <span>Wins and Opportunities example:<br/></span>
                  <span style={{ fontWeight:'bold' }}>A Customer ($M Waste Management, AMER) – B Partner (Premier) – Win: e-Commerce Redesign, N Year, $M ARR<br/></span>
                  <span>A Customer launched its redesigned AWS-powered e-commence site. The customer chose AWS because of its purpose built service model. 
                  This project merged US and Canada customers into a single e-commerce platform combining 6+ business process work streams and trouble ticket systems. 
                  The initially project uses a host of purpose-built AWS solutions including DynamoDB, ElasticSearch, RDS Aurora Postgres, Athena, Firehose, ECS, API GW, 
                  X-Ray, Load Balancer, WAF, Cloud Formation, KMS, CloudTrail, API Gateway, ECS, EC2, S3 and Lambda. Not public reference.
                  </span>
                </div>}
                {/*-----------Customer Highlights Example------------------------ */}
                {values && values.type === 'Customer Highlights'  &&
                <div class="alert alert-secondary" role="alert">
                  <span>Customer Highlights example:<br/></span>
                  <span style={{ fontWeight:'bold' }}>C Partner (GSI, Premier) – 5 D joint teams launched – Driving N wins & N opportunities<br/></span>
                  <span>C Partner and AWS have launched five D teams delivering customer prototypes across N countries in N continents and multiple industries. 
                  D team accelerates sales conversations with joint customers and create a significant impact on customers and partner's sales leadership. 
                  To date, D team has delivered N wins and N flight opportunities, and deployed N demos of AWS capabilities into N Partner locations. 
                  It is helping drive RFP outcomes and has been instrumental for customers to go direct to a sole source arrangement. 
                  The projects completed to date typically combine multiple AWS services including SageMaker, Connect, DynamoDB, EC2, S3, and Lambda. 
                  In addition, specialist prototypes have leveraged eco-system partners including SFDC and SAP together with core AWS services. 
                  </span>
                </div>}
                </Form.Group>
              </Form.Row>
              
              {/*-------------------------------Step2: for headline--------------------------------- */}
              {values && values.type &&
              
              <Form.Row>
                {/*-------------------------------Customer Name--------------------------------- */}
                <Form.Group md="10" style={{width:"190px", marginRight: "20px", marginLeft:"5px" }} >
                  <Form.Label>Customer Name</Form.Label>
                  <Form.Control
                    name="customerName"
                    value={values.customerName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.customerName}
                  />
                  <Form.Control.Feedback type="invalid">
                    Customer Name is required
                  </Form.Control.Feedback>
                </Form.Group>
                {/*-------------------------------Customer Geo--------------------------------- */}
                <Form.Group md="10" style={{marginRight: "20px", width:"130px"}}>
                  <Form.Label>Geo</Form.Label>
                  <Form.Control
                    as="select"
                    name="geo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.geo}
                  >
                    {geoList.map(d => (<option key={d.id} value={d.name}>{d.name}</option>))}
                  </Form.Control>
                </Form.Group>
                {/*-------------------------------Customer Industry--------------------------------- */}
                <Form.Group md="10" style={{marginRight: "20px", width:"170px"}}>
                  <Form.Label>Customer Industry</Form.Label>
                  <Form.Control
                    name="industry"
                    value={values.industry}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.industry}
                  />
                  <Form.Control.Feedback type="invalid">
                    Customer Industry is required
                  </Form.Control.Feedback>
                </Form.Group>
                {/*-------------------------------Customer Revenue--------------------------------- */}
                <Form.Group md="10" >
                  <Form.Label>Customer Revenue</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>$</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      name="customerRev"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.customerRev}
                      isInvalid={!!errors.customerRev}
                      style={{width:118}}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please input number only
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group style={{marginTop:'7px', width:'150px'}}>
                <Form.Label></Form.Label>
                  <Form.Control
                    as="select"
                    name="customerRevUnit"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.customerRevUnit}
                    placeholder="-Select unit-"
                  >
                  {unitList.map(d => (<option key={d.id} value={d.name}>{d.name}</option>))}
                  </Form.Control>
                </Form.Group>
                {/*--------------------------------Partner Name--------------------------------------- */}
                <Form.Row>
                <Form.Group md="10" style={{width:"190px",marginRight: "20px",marginLeft:"10px"}}>
                  <Form.Label>Partner Name</Form.Label>
                  <Form.Control
                    as="select"
                    name="partnerName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.partnerName}
                    required
                  >
                    {partnerNameList.map(d => (<option key={d.id} value={d.name}>{d.name}</option>))}
                  </Form.Control>
                </Form.Group>
                {/*--------------------------------Partner Tier--------------------------------------- */}
                <Form.Group md="10" style={{marginRight: "20px",width:"130px"}}>
                  <Form.Label>Partner Tier</Form.Label>
                  <Form.Control
                    as="select"
                    name="partnerTier"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.partnerTier}
                  >
                    {partnerTierList.map(d => (<option key={d.id} value={d.name}>{d.name}</option>))}
                  </Form.Control>
                </Form.Group>
                </Form.Row>
                {/* --------------------ARR (for only Wins & Opportunities)----------------------------------*/}
                {values && (values.type === 'Wins & Opportunities') &&
                <Form.Group md="10" style={{marginLeft:'4px'}}>
                  <Form.Label>ARR</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>$</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      name="arr"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.arr}
                      isInvalid={!!errors.arr}
                      style={{width:150}}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter number only
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                }
                {/* --------------------ARR Unit (Billion/Million/Thousand)----------------------------------*/}
                {values && (values.type === 'Wins & Opportunities') &&
                <Form.Group style={{marginTop:'7px', width:'150px'}}>
                <Form.Label></Form.Label>
                  <Form.Control
                    as="select"
                    name="arrUnit"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.arrUnit}
                    placeholder="-Select unit-"
                  >
                  {unitList.map(d => (<option key={d.id} value={d.name}>{d.name}</option>))}
                  </Form.Control>
                </Form.Group>}
                
                {/* --------------------ARR Unit (Billion/Million/Thousand)----------------------------------*/}
                <Form.Group as={Col} md="10">
                <Form.Label>Title of the Good News Story</Form.Label>
                <Form.Control
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.title}
                />
                <Form.Control.Feedback type="invalid">
                    Title is required
                  </Form.Control.Feedback>
                </Form.Group>

                {/*--------------------------Step3: for Story Body------------------------ */}
                
                <Form.Group as={Col} md="10">
                  <Form.Label>Story:
                    {values && values.type === 'Wins & Opportunities'  &&
                      <Form.Text>
                      If wins:<br/>
                      - What is the win?<br/>
                      - What is the workload/Partner Solutions?<br/>
                      - Why did we win?<br/>
                      If opportunities:<br/>
                      - What is the opportunity?<br/>
                      - What is the workload/Partner Solutions?<br/>
                      - Why does it matter?
                      </Form.Text>
                    }
                    {values && values.type === 'Customer Highlights' &&
                      <Form.Text>
                      - What is/are the highlight(s)?<br/>
                      - What is the workload/Partner Solutions?<br/>
                      - Why does it matter?
                      </Form.Text>
                    }
                  </Form.Label>
                  <Field name="story" style={{display: 'none'}} validate={wordsCountValidater} />
                  <Form.Control
                    as="textarea"
                    rows="5"
                    name="story"
                    placeholder="min 50 and max 150 words"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.story}
                    isInvalid={!!errors.story}
                  />
                  {/* words count */}
                  <div className="WordsCount">{`${values.story ? values.story.split(' ').length : 0}/150`}</div>
                  <Form.Control.Feedback type="invalid">
                    {errors.story}
                  </Form.Control.Feedback>
                  {/* Grammar Check(not implemented)
                  <Button size="sm" variant="info" style={{marginTop: 5}} onClick={() => {
                    handleGrammarCheck(values.story)
                  }}>Grammar Check</Button>*/}
                </Form.Group>

                {/*--------------------------Step3.1: Public Reference ------------------------ */}
                
                <Form.Group as={Col} md="10">
                  <Form.Label>Is the story reference-able to public?</Form.Label>
                  <div>
                    <Form.Check
                      custom
                      inline
                      type="radio"
                      label="Yes"
                      value="Yes"
                      name="refAble"
                      id="formHorizontalRadios4"
                      onChange={handleChange}
                    />
                    <Form.Check
                      custom
                      inline
                      type="radio"
                      label="No"
                      value="No"
                      name="refAble"
                      id="formHorizontalRadios5"
                      onChange={handleChange}
                    />
                  </div>
                </Form.Group>

                {values && values.refAble === 'Yes' &&
                  <Form.Group as={Col} md="10">
                    <Form.Label>Link of Public Reference(if any)</Form.Label>
                    <Form.Control
                      name="prlink"
                      value={values.prlink}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.prlink}
                    />
                  </Form.Group>
                }
                {/*------------------Step3.2: Submitter(Amazon Alias) ------------------ */}
                <Form.Group as={Col} md="10">
                  <Form.Label>Submitter(Amazon Alias)</Form.Label>
                  <Form.Control
                    name="submitter"
                    value={values.submitter}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.submitter}
                    style={{maxWidth:250}}
                    readOnly
                  />
                </Form.Group>
              </Form.Row>
              }
              {/*--------------------------Step4: Submit button----------------------------- */}
              <Button variant="primary" type="submit" style={{marginRight: 10}}>
                Submit
              </Button>
              
            </Form>
          )
        }}
      </Formik>
    </div>
    
  )
}

export default withRouter(FormPage)